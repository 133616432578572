import React from 'react'

interface SvgProps {
  className?: string
}

export const Github = ({ className }: SvgProps ) => (
  <svg className={className} aria-labelledby="simpleicons-github-icon" role="img" viewBox="0 0 24 24"><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" /></svg>
)

export const Minus = ({ className }: SvgProps ) => (
  <svg className={className} width="12" height="3" viewBox="0 0 12 3" fill="none">
    <path d="M11 1.5H1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const Plus = ({ className }: SvgProps ) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z" fill="black"/>
  </svg>
)

export const Close = ({ className }: SvgProps ) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4922 5.92205C13.8827 5.53152 13.8827 4.89836 13.4922 4.50783C13.1017 4.11731 12.4685 4.11731 12.078 4.50783L9 7.58583L5.92201 4.50783C5.53148 4.11731 4.89832 4.11731 4.50779 4.50783C4.11727 4.89836 4.11727 5.53152 4.50779 5.92205L7.58579 9.00004L4.50779 12.078C4.11727 12.4686 4.11727 13.1017 4.50779 13.4922C4.89832 13.8828 5.53148 13.8828 5.92201 13.4922L9 10.4143L12.078 13.4922C12.4685 13.8828 13.1017 13.8828 13.4922 13.4922C13.8827 13.1017 13.8827 12.4686 13.4922 12.078L10.4142 9.00004L13.4922 5.92205Z" fill="currentColor"/>
  </svg>
)

export const Circle = ({ className }: SvgProps ) => (
<svg width="1rem" height="1rem" viewBox="0 0 16 18" fill="none">
  <circle cx="8" cy="10" r="8" fill="white"/>
</svg>
)

export const SmallerLogo = ({ className }: SvgProps ) => (
<svg width="55" height="16" viewBox="0 0 55 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.132 4.79068C7.7405 4.09301 7.17003 3.65115 6.30873 3.65115C5.48099 3.65115 4.57495 4.31394 4.44072 5.22092C4.08278 7.55813 9.53021 6.58138 8.82551 11.2442C8.40045 14.0233 6.43177 16 3.70246 16C1.85682 16 0.682327 14.8954 0 13.2907L1.94631 11.5814C2.13647 12.6512 2.93065 13.6047 4.04922 13.6047C5.11186 13.6047 5.90604 12.6628 6.07383 11.593C6.29754 10.1512 5.0783 9.75581 4.09396 9.32558C2.48322 8.59302 1.36465 7.68604 1.70022 5.53487C2.04698 3.23254 3.98211 1.37207 6.23043 1.37207C7.41611 1.37207 8.97092 1.97672 9.64206 2.91859L8.132 4.79068Z" fill="white"/>
  <path d="M12.6174 7.38395H12.6509C13.2438 6.66302 14.1498 6.2793 14.944 6.2793C16.029 6.2793 16.8008 6.74441 17.2259 7.6979C17.897 6.81418 18.9708 6.2793 19.9776 6.2793C22.1923 6.2793 22.4832 8.01186 22.1811 9.97698L21.3198 15.6165H18.8366L19.5637 10.8607C19.6979 9.97698 19.9999 8.33744 18.7807 8.33744C17.4384 8.33744 17.1588 10.2328 17.0245 11.1747L16.3534 15.6165H13.8702L14.5637 11.0351C14.6979 10.1514 15.0782 8.34907 13.7919 8.34907C12.4048 8.34907 12.1923 10.2328 12.0469 11.1863L11.3758 15.6282H8.88135L10.2572 6.58162H12.7404L12.6174 7.38395Z" fill="white"/>
  <path d="M30.8726 15.6161H28.4229L28.546 14.7905H28.5236C27.886 15.5115 26.9017 15.9185 25.9957 15.9185C23.4453 15.9185 22.3603 13.4998 22.7294 11.058C23.0986 8.66263 24.9218 6.29053 27.4274 6.29053C28.3335 6.29053 29.1948 6.69751 29.6757 7.43007L29.81 6.58123H32.2596L30.8726 15.6161ZM29.3625 11.0929C29.5527 9.87193 28.9487 8.5696 27.6735 8.5696C26.3983 8.5696 25.414 9.87193 25.235 11.0929C25.0449 12.3138 25.6265 13.6394 26.9017 13.6394C28.1769 13.6394 29.1836 12.3022 29.3625 11.0929Z" fill="white"/>
  <path d="M34.4632 15.6163H31.9912L34.3626 0H36.8458L34.4632 15.6163Z" fill="white"/>
  <path d="M37.9866 15.6163H35.5034L37.886 0H40.3692L37.9866 15.6163Z" fill="white"/>
  <path d="M42.0137 11.5119C41.8348 12.6514 42.0697 13.9421 43.3672 13.9421C44.1837 13.9421 44.7766 13.4305 45.224 12.7095L47.45 13.0933C46.5775 14.8956 44.8996 15.9072 43.0652 15.9072C40.3694 15.9072 39.0271 13.9189 39.441 11.1747C39.8549 8.43046 41.5887 6.2793 44.4075 6.2793C47.2598 6.2793 48.1994 8.47698 47.7855 11.1979L47.7408 11.5119H42.0137ZM45.6379 9.90721C45.6491 8.98861 45.224 8.08163 44.2732 8.08163C43.2777 8.08163 42.5954 8.96535 42.3269 9.90721H45.6379Z" fill="white"/>
  <path d="M51.6219 7.51186H51.6554C52.2706 6.72116 53.0872 6.2793 54.0044 6.2793C54.3623 6.2793 54.6979 6.37232 54.9999 6.50023L54.3288 8.89558C54.0603 8.67465 53.7248 8.55837 53.3556 8.55837C51.633 8.55837 51.2192 10.1398 51.0066 11.5468L50.3914 15.6165H47.9082L49.284 6.57H51.7673L51.6219 7.51186Z" fill="white"/>
</svg>
)

export const BasketFull = ({ className }: SvgProps ) => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" stroke="white" strokeWidth="1">
  <path  d="M13.61,6.1V4.93a3.3,3.3,0,1,0-6.6,0V6.1H2.32V17.63h16V6.1ZM7.52,4.93A2.79,2.79,0,0,1,10.3,2.12a2.83,2.83,0,0,1,2.82,2.81V6.1H7.52ZM17.81,17.12h-15V6.61h15Z" fill="white" transform="translate(-2.32 -1.62)"/>
  <path  d="M16.2,15.33H4.39V8.66H16.2Z" fill="white" transform="translate(-2.32 -1.62)"/>
  </svg>
)

export const Basket = ({ className }: SvgProps ) => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" stroke="white" strokeWidth="1">
  <path   d="M13.61,6.1V4.93a3.3,3.3,0,1,0-6.6,0V6.1H2.32V17.63h16V6.1ZM7.52,4.93A2.79,2.79,0,0,1,10.3,2.12a2.83,2.83,0,0,1,2.82,2.81V6.1H7.52ZM17.81,17.12h-15V6.61h15Z" fill="white" transform="translate(-2.32 -1.62)"/>
  </svg>
)

export const Arrow = ({ className }: SvgProps ) => (
<svg width="11" height="11" className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.79 19.09" fill="none" stroke="black" strokeWidth="0.5">
  <polygon points="2.7 17.58 1.7 16.54 15.81 2.95 4.12 2.95 4.12 1.5 18.32 1.5 18.32 14.59 16.87 14.59 16.87 3.92 2.7 17.58" fill="black"/>
</svg>
)


export const Human = ({ className }: SvgProps ) => (
<svg width="10" height="29" viewBox="0 0 10 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.99226 4.16996C4.04647 4.16996 3.46977 3.47497 3.38903 2.54438C3.36597 2.30879 3.36597 1.87295 3.38903 1.63736C3.46977 0.694994 4.04647 0 4.99226 0C5.93805 0 6.51475 0.694994 6.59549 1.62558C6.61856 1.86117 6.61856 2.29701 6.59549 2.5326C6.52629 3.47497 5.93805 4.16996 4.99226 4.16996Z" fill="white"/>
<path d="M0.170884 11.2964C0.447701 9.41166 1.18588 7.75075 2.22394 6.18407C2.81218 5.28882 4.06938 5.21814 5.00364 5.25348C5.9379 5.21814 7.19511 5.28882 7.78334 6.18407C8.80987 7.75075 9.54805 9.41166 9.82486 11.2964C10.0671 12.9691 9.99787 14.6889 9.95174 16.3734C9.95174 16.3852 9.95174 16.3969 9.95174 16.4087C9.66339 17.516 8.30237 17.1979 8.16396 16.1731C8.16396 16.1613 8.16396 16.1496 8.16396 16.1378C8.40618 13.0162 8.34851 11.8972 7.65647 9.6237C7.64493 9.58836 7.5988 9.60014 7.5988 9.63548C7.79487 13.9232 7.49499 25.1845 7.27584 27.3166C7.24124 27.6817 6.89522 27.9762 6.5492 28.0116C6.08784 28.0587 5.64955 27.6229 5.67261 27.1517C5.79949 23.8063 5.71875 20.3431 5.61494 17.0095C5.60341 16.5383 5.32659 16.5383 5.00364 16.4558C4.69222 16.5501 4.41541 16.5383 4.39234 17.0095C4.28853 20.3431 4.20779 23.8063 4.33467 27.1517C4.35774 27.6229 3.90791 28.0587 3.45808 28.0116C3.11206 27.9762 2.76604 27.6817 2.73144 27.3166C2.51229 25.1845 2.21241 13.9232 2.40848 9.63548C2.40848 9.60014 2.36235 9.60014 2.35081 9.6237C1.65877 11.8972 1.6011 13.0162 1.84332 16.1378C1.84332 16.1496 1.84332 16.1613 1.84332 16.1731C1.69338 17.1979 0.343894 17.516 0.0555439 16.4087C0.0555439 16.3969 0.0555439 16.3852 0.0555439 16.3734C-0.00212669 14.6889 -0.0713301 12.9691 0.170884 11.2964Z" fill="white"/>
</svg>
)
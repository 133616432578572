import React, { useState } from 'react'
import styled from 'styled-components'

import cx from "classnames"
import { PageLink } from 'src/components/link'
import { Disclaimer } from 'src/components/disclaimer'

import { useCartCount, useToggleCart, useToggleNav, useStore } from 'src/context/siteContext'

//Clocks react
import Clock from 'react-live-clock';

import { SmallerLogo } from 'src/components/svgs'
import HumanAnimation  from 'src/images/Human_1.gif'

import { device } from 'src/styles/device';


//Styled Components - HEADER
const paddingHeaderTop = '10px';
const borderStyle = '1px solid #dadada';


const Header = styled.header`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    color: #ffffff;
    background: #000000;
    grid-area: Header;
    a {
    color:#ffffff;
    transition: opacity .3s;
    text-decoration: none;
    text-underline-offset: 1px;
    opacity:1;
    }

    a:hover {
        opacity:0.4;
    }

    @media ${device.laptop} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;

//1210px media query

const Title = styled.div`
     padding:${paddingHeaderTop};
        min-height: 120px;
        grid-column: 1 / -1;
`

const Desc = styled.div`
    display:none;

    @media ${device.laptop} {
    border-top: ${borderStyle};
    display: grid;
    grid-column: span 2;
    border-bottom: ${borderStyle};
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.035em;
    height:auto;
    padding:${paddingHeaderTop};
    p{
        margin: 0;
    }
    }
`
const TimeBoxes = styled.div`
    grid-column: 1 / -1;
    display: grid;
    
    grid-template-columns: 1fr 1fr 1fr;
    border-left: none;
    border-top: ${borderStyle};  
    border-bottom: ${borderStyle};  
    @media ${device.laptop} {
        grid-column: span 2;
        border-top: ${borderStyle};  
        border-left: ${borderStyle};
        border-bottom: ${borderStyle};   
    }
`

const Time = styled.div`
    h3{
        font-family: 'ABC Monument Grotesk';
        font-weight: normal;
        font-size: 	1.2em;
        margin:0;
    }
    height:68px;
    font-family: 'Courier Prime Sans';
    font-size: 0.6875em;
    display: grid;
    justify-content: right;
    align-content: center;
    grid-template-rows: 1fr 1fr;
    text-align: right;
    padding:10px;
    border-left: ${borderStyle};
    &:last-child {
        /* padding:20px 10px 20px 20px; */
    }
    &:first-child {
        border-left: none;
    }
    img {
        height:33px
    }
`
// CSS Styles via SyledCompenents 
export const HeaderPassword = () => {
const displayNone = {
  display: 'none'
} as const;

  return (
    <Header>
        <Title>
             <PageLink to='/' ><SmallerLogo /></PageLink>
        </Title>

        <Desc>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in mauris nulla. Mauris sodales massa ex,
            sit amet imperdiet nibh aliquam at. Curabitur porta pretium lobortis. In auctor velit ut eleifend
            dignissim. Donec congue turpis bibendum nibh.</p>
        </Desc>

        <TimeBoxes>
            <Time>
                <h3>New York Time:</h3>
                <Clock format={'h:mm:ssa'} ticking={true} timezone={'America/New_York'} />
            </Time>
            <Time>
                <h3>Portland Time:</h3>
                <Clock format={'h:mm:ssa'} ticking={true} timezone={'US/Pacific'} />
            </Time>
            <Time>
                <h3>Studio Time:</h3>
                <div><img src={HumanAnimation} alt="Human Animation Running" />
                     <img src={HumanAnimation} alt="Human Animation Running" />
                     <img src={HumanAnimation} alt="Human Animation Running" />
                </div>
            </Time>
        </TimeBoxes>

    </Header>    
  )
}
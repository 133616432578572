import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Helmet from 'react-helmet'
import tighpo from 'tighpo'


import { Header } from 'src/components/header'
import { HeaderPages } from 'src/components/headerPages'
import { Footer } from 'src/components/footer'
import { FooterPages } from 'src/components/footerPages'
import { SwitchTransition, Transition } from 'react-transition-group'
import { CartDrawer } from 'src/components/cartDrawer'
import { PasswordWrapper } from './password'

import Analytics from 'src/components/analytics'

import CookieConsent from "react-cookie-consent";

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
      box-sizing: inherit;
  }

  html, body, .grid-container { 
    margin: 0;
    font-family: 'ABC Monument Grotesk', Helvetica, Arial, Sans-Serif;
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .grid-container{
    display: grid;
    grid-template-areas:
      "Header"
      "Main"
      "Footer";
      grid-template-rows: auto 1fr auto;
      /* grid-template-rows: auto 1fr 44px; */
  }

  /* h1{
    font-family: 'NeueHaasGroteskDisp Std';
    font-weight: normal;
  }
  h2{
    font-family: 'NeueHaasGroteskDisp Std';
    font-weight: normal;
  }
  h3{
    font-family: 'NeueHaasGroteskDisp Std';
    font-weight: normal;
  } */
  `
// Styles for Styled-Components 
// const Grid = styled.div`
//   display: grid;
//   grid-template-areas:
//       "Header"
//       "Main"
//       "Footer";
//   grid-template-rows: 1fr 2fr 1fr;
//   ` 


const TRANSITION_DURATION = 400;
const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

import 'src/styles/fonts.scss'
import 'src/styles/site.scss'

const Layout = ({ children, siteMetadata, location, pageContext }: { children: any }) => {

  if (pageContext.layout === 'news') {
    return (
      <React.Fragment>
        
        
        <GlobalStyle />
        
        <div className="grid-container">
        <PasswordWrapper>
        <HeaderPages />
        
        {!/account/.test(location.pathname) ? (
        <SwitchTransition>
                  <Transition
                    key={location.pathname}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    appear={true}
                    timeout={TRANSITION_DURATION}>
                      {status => (
                        <div 
                          className="site"
                          id='maincontent'
                          style={{
                            ...TRANSITION_STYLES.default,
                            ...TRANSITION_STYLES[status],
                          }}>
                  {children}
                  </div>
                      )}
                  </Transition>
                </SwitchTransition>
                ) : (
                <div>
                  {children}
                </div>
              )}

        <FooterPages />
        </PasswordWrapper>
        
        </div>
      </React.Fragment>
    )
  }

  if (pageContext.layout === 'studio') {
    return (
      <div>
         <GlobalStyle />
        <Helmet title='Studio' />
        
      <HeaderPages />
      /Studio
      {children}
      <FooterPages />
    </div>
    )
  }

  if (pageContext.layout === 'index') {
    return (
      <div>
      hello index archives
      {children}
    </div>
    )
  }

  // Render documentation for CMS minus header/footer experience
  if (pageContext.layout === 'docs') {
    return (
      <div>
        hello docs
        {children}
      </div>
    )
  }

    // Render documentation for CMS minus header/footer experience
    // if (pageContext.layout === 'home' ? '/' : '/') {
    if (pageContext.layout === 'home') {
      return (
        <React.Fragment>
        <GlobalStyle />
        <div className="grid-container">
        <PasswordWrapper>
        <HeaderPages />
        
        {/* <CartDrawer /> */}
        {!/account/.test(location.pathname) ? (
        <SwitchTransition>
                  <Transition
                    key={location.pathname}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    appear={true}
                    timeout={TRANSITION_DURATION}>
                      {status => (
                        <div 
                          className="site"
                          id='maincontent'
                          style={{
                            ...TRANSITION_STYLES.default,
                            ...TRANSITION_STYLES[status],
                          }}>
                  {children}
                  </div>
                      )}
                  </Transition>
                </SwitchTransition>
                ) : (
                <div>
                  {children}
                </div>
              )}
              <FooterPages />
        </PasswordWrapper>
        
        </div>
        
      </React.Fragment>
      )
    }

    if (pageContext.layout === 'accounts') {
      return (
        <React.Fragment>
          <Helmet title='Accounts' />
          <HeaderPages />
          <div>{children}</div>
          <FooterPages />
        </React.Fragment>
      )
    }


  // useEffect(() => {
  //   tighpo('spaghetti', function () {
  //     const style = document.createElement('style')
  //     document.body.appendChild(style)
  //     style.sheet.insertRule('html, body { cursor: url(https://spaghet.now.sh), auto !important; }')
  //   })
  // }, [0])

  

  return (
    <React.Fragment>
      <GlobalStyle />

      <Analytics
        googleAnalyticsPropertyId={process.env.GATSBY_GA_ID} />
          <div className="grid-container">
          <PasswordWrapper>
            {/* <div>
              <a
                name='maincontent'
                className='pf top left z10 skip'
                href='#maincontent'
              >
                Skip to main content
              </a>
            */}
              <HeaderPages />
              
              
              {/* <CartDrawer /> */}
              {/* 
              
                Smooth transition credits to Ian Williams: https://github.com/dictions
              
              */}
              {!/account/.test(location.pathname) ? (
                <SwitchTransition>
                  <Transition
                    key={location.pathname}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    appear={true}
                    timeout={TRANSITION_DURATION}>
                      {status => (
                        <div 
                          className="site"
                          id='maincontent'
                          style={{
                            ...TRANSITION_STYLES.default,
                            ...TRANSITION_STYLES[status],
                          }}>
                          {children}
                        </div>
                      )}
                  </Transition>
                </SwitchTransition>
              ) : (
                <div>
                  {children}
                </div>
              )}
              <FooterPages />
            </PasswordWrapper>
            </div>
    </React.Fragment>
  )
}

export default Layout
import React, { useEffect, useState, FormHTMLAttributes } from 'react'
import cookie from 'js-cookie'
import cx from 'classnames'
import styled from 'styled-components'

import { device } from 'src/styles/device';
import { HeaderPassword } from 'src/components/headerPassword'
import { FooterPassword } from 'src/components/footerPassword'

const Main = styled.div`
  display: grid;
  grid-area: Main;
  margin: 0 auto;
  min-height: 80vh;
  justify-content: center;
  align-items:center;
  grid-template-rows: 1fr 1fr;
  padding:20px;
  h3{
    font-size:4em;
    font-weight:300;
    margin-top:0;
    margin-bottom:20px;
    line-height:0.9em;
  }
  p{
    margin:0;
    line-height:1em;
  }
  input[type=password]{
     border-radius: 20px;
     border: 1px solid #dcdcdc;
     color: #C6C6C6;
     width: 250px;
     height: 30px;
     padding-left: 10px;
     margin-right: 10px;
    }
    
input[type=password]:focus {
     outline: none;
     border: 1px solid #333333;
     color: #000000;
}
button {
  background-color: #62C462;
  border: none;
  color: white;
  padding: 8px 13px 8px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8em;
  margin-left: 0px;
  margin-top: 20px;
  border-radius: 24px;
  cursor: pointer;
}

` 
import 'src/styles/fonts.scss'
import 'src/styles/site.scss'

const passwords = [
  'smaller2021'
]

const browser = typeof window !== 'undefined' && window;

export const PasswordWrapper = ({ children }: {
  children: any
}) => {
  // Comment this out to turn the password back on!
  const [password, setPassword] = useState(cookie.get('password'))
  // const [password, setPassword] = useState('spaghetti')
  const [error, setError] = useState(false)
  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (form.current) {
      const { elements } = form.current
      const found = passwords.filter(el =>  el === elements.password.value)

      if (found.length === 1) {
        cookie.set('password', 'true', { expires: 30 })
        location.reload()
      } else {
        setError(true)
      }
    }
  }
  if (password) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        {browser && (
           
                <form onSubmit={(e) => handleSubmit(e)} ref={form}>
                  <HeaderPassword />
                  <Main>
                  <div>
                  <h3>We'll be back.</h3>
                  <p>We're busy updating the Smaller site and store for you and will be back soon.</p>
                  </div>
                  <div>{error && (<span>We're sorry that's not a correct password.</span>)}</div>
                  <div>
                    <label>
                    <span className="superscript">Password: </span>
                      <input aria-label='password' onChange={() => setError(false)} className={cx('', {
                        'error': error
                      })} type='password' name='password' placeholder='Enter Password' />
                    </label>
                    <button type='submit' aria-label='submit'>
                      Submit
                    </button>          
                  </div>
                  </Main>   
                  <FooterPassword />
                </form>
                     
        )}

      </React.Fragment>
    )
  }
}
import React, { useState } from 'react'
import styled from 'styled-components'

import cx from "classnames"
import { PageLink } from 'src/components/link'
import { Disclaimer } from 'src/components/disclaimer'

import { useCartCount, useToggleCart, useToggleNav, useStore } from 'src/context/siteContext'

//Clocks react
import Clock from 'react-live-clock';

import { Circle, Close, Human, SmallerLogo, Basket, BasketFull } from 'src/components/svgs'
import HumanAnimation  from 'src/images/Human_1.gif'

import { device } from 'src/styles/device';


//Styled Components - HEADER
const paddingHeaderTop = '10px';
const borderStyle = '1px solid #dadada';


const Header = styled.header`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    color: #ffffff;
    background: #000000;
    grid-area: Header;
    a {
    color:#ffffff;
    transition: opacity .3s;
    text-decoration: none;
    text-underline-offset: 1px;
    opacity:1;
    }

    a:hover {
        opacity:0.4;
    }

    @media ${device.laptop} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;

//1210px media query

const Title = styled.div`
    min-height:80px;
    padding:${paddingHeaderTop};
        grid-column: span 2;
    @media ${device.tablet} {
        padding:${paddingHeaderTop};
        min-height: 120px;
        grid-column: span 2;
    }
`

const Nav = styled.div`
        display:none;
        .active{
        text-decoration: underline;
        opacity:0.8;
    }
    @media ${device.tablet} {
        display: grid;
        grid-column: span 2;
        grid-auto-flow: column;
        padding:${paddingHeaderTop};
        border-left: ${borderStyle};
    }
`

const MobileButton = styled.button`
  background: #000000;
  color:#ffffff;
  align-self: start;
  border: 0;
  padding: 0;
  font-size:1em;
  cursor: pointer;
  &:focus {outline:0;}
  &[aria-expanded="false"] div.close {
    display: none;
  }

  &[aria-expanded="true"] div.close {
    display: inline-block;
    font-size:1.5em;
  }

  &[aria-expanded="true"] div.open {
    display: none;
  }
`

const MobileNav = styled.div`
        font-size: 1.2em;
        display: grid;
        grid-column: span 2;
        grid-auto-flow: column;
        padding:${paddingHeaderTop};
        border-left: ${borderStyle};
        justify-items: end;
    @media ${device.tablet} {
        display:none;
    }

`

const MobileExtendNav = styled.div`
    grid-column: 1 / -1;
    max-height:0;
    transform: rotateX('90');
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    &[aria-expanded='true'] {
        display: grid;
        max-height: 500px; 
        transform: rotateX('0');
    }
    @media ${device.tablet} {
    display:none;
    &[aria-expanded='true'] {
        display: none;
    }
    }
`


const Link = styled.div`
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
    grid-gap: 1.5em;
    padding: 10px 0 10px 0;
    font-size:2em;
    border-bottom: 1px solid #dcdcdc;
    &:first-child {
        border-top: 1px solid #dcdcdc;
    }
    &:last-child {
        border-bottom: none;
    }
    svg{
        padding-top: 4px;
        width:30px;
        height:30px;
    }
    @media ${device.tablet} {
        display: grid;
        justify-content: right;
        grid-auto-flow: column;
        grid-gap: 1.5em;
        padding-top: 3px;
        font-size: 1.2em;
        border-bottom: none;
        svg{
        padding-top: 3px;
        width:18px;
        height:18px;
    }
        &:first-child {
        border-top: none;
    }
    &:last-child {
        border-bottom: none;
    }
    }
`


const Desc = styled.div`
    display:none;

    @media ${device.laptop} {
    border-top: ${borderStyle};
    display: grid;
    grid-column: span 2;
    border-bottom: ${borderStyle};
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.035em;
    height:auto;
    padding:${paddingHeaderTop};
    p{
        margin: 0;
    }
    }
`
const TimeBoxes = styled.div`
    grid-column: 1 / -1;
    display: grid;
    
    grid-template-columns: 1fr 1fr 1fr;
    border-left: none;
    border-top: ${borderStyle};  
    border-bottom: ${borderStyle};  
    @media ${device.laptop} {
        grid-column: span 2;
        border-top: ${borderStyle};  
        border-left: ${borderStyle};
        border-bottom: ${borderStyle};   
    }
`

const Time = styled.div`
    h3{
        font-family: 'ABC Monument Grotesk';
        font-weight: normal;
        font-size: 	1.2em;
        margin:0;
    }
    height:68px;
    font-family: 'Courier Prime Sans';
    font-size: 0.6875em;
    display: grid;
    justify-content: right;
    align-content: center;
    grid-template-rows: 1fr 1fr;
    text-align: right;
    padding:10px;
    border-left: ${borderStyle};
    &:last-child {
        /* padding:20px 10px 20px 20px; */
    }
    &:first-child {
        border-left: none;
    }
    img {
        height:33px
    }
`
const AccountCart = styled.div`
    display: grid;
    grid-column: 1 / -1;
    justify-content: right;
    align-content: center;
    grid-auto-flow: column;
    padding:${paddingHeaderTop};
    border-bottom: ${borderStyle};
    & div {
        padding-left: 5px;
    }
    svg{
        top: 1px;
    position: relative;
    padding-top: 2px;
    padding-right: 2px;
    } 
    a{
     margin-left: 10px;   
    }
`

// CSS Styles via SyledCompenents 
export const HeaderPages = () => {
  const {customerName} = useStore()
  const { navIsOpen } = useStore()
  const count = useCartCount()
  const toggleCart = useToggleCart()
  const toggleNav = useToggleNav()

  console.log(toggleNav);


 

const basketicon = count > 0 ? <BasketFull /> : <Basket />

const displayNone = {
  display: 'none'
} as const;

  return (
    <Header>
        <Title>
             <PageLink to='/' ><SmallerLogo /></PageLink>
        </Title>

        <Nav>
            <Link><PageLink to='/info'>Info</PageLink></Link>
            <Link><PageLink to='/news'>News</PageLink></Link>
            <Link><PageLink to='/studio'>Studio</PageLink></Link>
            <Link><PageLink to='/shop'>Shop</PageLink></Link>
            <Link><PageLink to='/index'>INDEX <Circle /></PageLink></Link>
        </Nav>
        <MobileNav>
        <MobileButton onClick={() => toggleNav()} aria-expanded={cx(navIsOpen && 'true' || 'false')}>
            <div className="open">☰</div>
            <div className="close">×</div>
         </MobileButton>
          {/* <button type='reset' className={cx('cart__drawer-bg bg--white z9 left top x y pf', navIsOpen && 'true' || 'false')} onClick={() => toggleNav()}>
            <Close className='db m1' />
          </button> */}
        </MobileNav>

        <MobileExtendNav aria-expanded={cx(navIsOpen && 'true' || 'false')}>
                <Link><PageLink to='/info'>Info</PageLink></Link>
                <Link><PageLink to='/news'>News</PageLink></Link>
                <Link><PageLink to='/studio'>Studio</PageLink></Link>
                <Link><PageLink to='/shop'>Shop</PageLink></Link>
                <Link><PageLink to='/index'>INDEX <Circle /></PageLink></Link>
        </MobileExtendNav>


        <Desc>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in mauris nulla. Mauris sodales massa ex,
            sit amet imperdiet nibh aliquam at. Curabitur porta pretium lobortis. In auctor velit ut eleifend
            dignissim. Donec congue turpis bibendum nibh.</p>
        </Desc>

        <TimeBoxes>
            <Time>
                <h3>New York Time:</h3>
                <Clock format={'h:mm:ssa'} ticking={true} timezone={'America/New_York'} />
            </Time>
            <Time>
                <h3>Portland Time:</h3>
                <Clock format={'h:mm:ssa'} ticking={true} timezone={'US/Pacific'} />
            </Time>
            <Time>
                <h3>Studio Time:</h3>
                <div><img src={HumanAnimation} alt="Human Animation Running" />
                     <img src={HumanAnimation} alt="Human Animation Running" />
                     <img src={HumanAnimation} alt="Human Animation Running" />
                </div>
            </Time>
        </TimeBoxes>

        <AccountCart>
            <div>
                <a aria-label='cart' className='' onClick={() => toggleCart()}>
                {basketicon} Cart (<span className=''>{count}</span>) 
                </a>
            </div>
            <div>
                <a href='/account' className=''>{customerName ? `Hi, ${customerName}` : 'Account'}</a>
            </div>
        </AccountCart>

    </Header>    
  )
}
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"smaller","short_name":"smaller","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon/apple-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a86d085f5ff3b4ab55cc1a9f147b84f8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/web/src/layouts/index.tsx"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React from 'react'
import styled from 'styled-components'

import Marquee from "react-fast-marquee";
import { useStaticQuery, graphql } from "gatsby";

import { Arrow } from 'src/components/svgs'

import { device } from 'src/styles/device';

//Styled Components - Footer

const Padding = '10px';
const borderStyle = '1px solid #dadada';

const Footer = styled.footer`
  display: grid;
  /* grid-template-rows: 1fr 1fr 1fr; */
  grid-template-areas: 
  "Marquee"
  "NavLinks" 
  "SignOff";
  grid-area: Footer;
  border-top:${borderStyle};
  /* height:36px; */
  /* align-content: center; */
  box-sizing: border-box;
  a {
    color: #000000;
    transition: .5s;
    text-decoration: none;
    opacity:1;
  }

  a:hover {
    opacity:0.6;
  }
  // 1384px break point to 3 rows
  /* @media ${device.tablet} {
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-areas: "SignOff Marquee NavLinks";
  } */
  @media ${device.laptop} {
  grid-template-columns: 1fr 1fr 3fr;
  grid-template-rows:1fr;
  grid-template-areas: "SignOff NavLinks Marquee ";
  
  }

  ` 

const NavLinks = styled.div`
    display:grid;
    align-items: center;
    grid-area: NavLinks; 
    justify-items: start;
    line-height:0.95em;
    grid-column:span 1;
    padding:10px 5px 10px 5px;
  @media ${device.laptop} {
    padding-left:${Padding};
    padding-right:${Padding};
    justify-items: end;
  }
`

const SignOff = styled.div`
  font-family: 'Courier Prime Sans';
  font-weight: normal;
  font-style: normal;
  display:grid;
  grid-area: SignOff;
  /* grid-column:span 1; */
  font-size:.5rem;
  justify-items: start;
  align-items: center;
  align-content: center;
  padding: 10px 5px 10px 5px;
  border-top: 1px solid #dcdcdc;
  > div{
    margin:0;
  }
  @media ${device.laptop} {
    grid-column: 1;
    padding-left:${Padding};
    padding-right:${Padding};
    border-top: none;
    
  }
`

// const MarqueeTxt = {
//   display: "grid",
//   gridArea: "Marquee",
//   whiteSpace: "nowrap",
//   overflow: "hidden",
//   alignItems: "center"
// };
export const FooterPassword = () => {
  const data = useStaticQuery(graphql`
  {
    allSanitySiteGlobal {
      nodes {
        content {
          globalSettings {
            globalTitle
            globalPhoneNumber
          }
        }
      }
    }
  }
  `);
  return (
  <Footer>
    <SignOff>
      <div>SMALLER BRAND INC.</div>
      <div>NEW YORK / PORTLAND, U.S.A</div>
    </SignOff>
    <Marquee className="MarqueeTxt"
      speed={20}

      pauseOnHover={true}
      // No fade, remove comment below
      gradient={false}
      >
      <div>We'll be back.</div>
      <div><a href="tel:+1611-762–5537"><Arrow />{data.allSanitySiteGlobal.nodes[0].content.globalSettings.globalPhoneNumber} <span className="superscript">(762–5537)</span></a></div>
      </Marquee>
    <NavLinks>
      <div>2021 — SM© </div>
    </NavLinks>
  </Footer>
  )
}
